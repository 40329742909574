'use strict';

console.clear();


function markVisited(element) {
    // Ajoute la classe 'visited' à l'élément parent de l'image cliquée
    element.classList.add('visited');
}



